exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-bakers-js": () => import("./../../../src/pages/work/bakers.js" /* webpackChunkName: "component---src-pages-work-bakers-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-seltzer-js": () => import("./../../../src/pages/work/seltzer.js" /* webpackChunkName: "component---src-pages-work-seltzer-js" */),
  "component---src-pages-work-tiny-js": () => import("./../../../src/pages/work/tiny.js" /* webpackChunkName: "component---src-pages-work-tiny-js" */),
  "component---src-pages-work-viva-js": () => import("./../../../src/pages/work/viva.js" /* webpackChunkName: "component---src-pages-work-viva-js" */),
  "component---src-pages-work-zanelli-js": () => import("./../../../src/pages/work/zanelli.js" /* webpackChunkName: "component---src-pages-work-zanelli-js" */)
}

